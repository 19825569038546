























import Vue from "vue";
export default Vue.extend({
  data: () => ({
    groups: [
      {
        src: "backgrounds/bg-2.jpg",
        name:"语音前端",
        path: "/group/fe"
      },
      {
        src: "backgrounds/md.jpg",
        name: "语音识别",
        path: "/group/asr"
      },
      {
        src: "backgrounds/bg.jpg",
        name: "语音合成",
        path: "/group/tts"
      }, 
      {
        src: "backgrounds/md2.jpg",
        name:"声纹识别",
        path: "/group/sv"
      },
    ],
    selected: [],
    title: "研究方向",
  }),
});
