




















import Vue from "vue";
import axios from "axios";
import HomeModuleHeader from "./HomeModuleHeader.vue";
export default Vue.extend({
  components: { HomeModuleHeader },
  data: () => ({
    selectedItem: 1,
    recommends: [] as any,
    page: 1,
    numInOnePage: 7,
    recommendHeaderProp: {
      title: "成果推介",
      button: "更多",
      path: "/recommend"
    },
  }),
    mounted: function () {
    this.getRecommendList();
    console.log()
  },
  methods: {
    getRecommendList() {
      axios
        .post(Vue.prototype.serverAddr + "/api/get-item-list", {
          collection: "article",
          need:{
            "type":"recommend"
          },
          page: this.page,
          numInOnePage: this.numInOnePage,
        })
        .then((response) => {
          console.log("get response");
          this.recommends = response.data;
        });
    },
    gotoRecommend: function (id: string, link: string) {
      if (link != "") {
        window.location.href = link;
      } else {
        this.$router.push({
          path: `/article/recommend/${id}`,
        });
        console.log(id);
      }
    },
  },
});
