
























































import Vue from "vue";
export default Vue.extend({
  props: ["items", "tab", "isEn"],
  data: () => ({
    logoImg: require("../assets/aslp-logo.png"),
    wechatImg: require("../assets/wechat2.jpg"),
  }),
});
