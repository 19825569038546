




















import Vue from "vue";
import TabBar from "./components/TabBar.vue";
import axios from "axios";
export default Vue.extend({
  components: { TabBar },
  name: "App",
  data: () => ({
    recordInfo: '陕ICP备15008649号-1',
    topTab: 0,
    isEn:false,
    mainTabBarItems: [
      {
        name: "首页",
        path: "/",
        key: 0,
      },
      {
        name: "实验室简介",
        path: "/introduction",
        key: 1,
      },
      {
        name: "新闻公告",
        path: "/article",
        key: 2,
      },
      {
        name: "实验室人员",
        path: "/staff",
        key: 3,
      },
      {
        name: "研究方向",
        path: "/group",
        key: 4,
      }, 
      {
        name: "研究成果",
        path: "/achievement",
        key: 5,
      },
      {
        name: "成果推介",
        path: "/recommend",
        key: 6,
      },
    ],
    loggedTabBarItems: [
      {
        name: "文章管理",
        path: "/backstage/article",
        key: 0,
      },
      {
        name: "介绍管理",
        path: "/backstage/intro",
        key: 1,
      },
      {
        name: "人员管理",
        path: "/backstage/staff",
        key: 2,
      },
      {
        name: "成果管理",
        path: "/backstage/achievement",
        key: 3,
      },
      {
        name: "退出登录",
        path: "/",
        key: 4,
      },
    ],
    unloggedTabBarItems: [
      {
        name: "返回主页",
        path: "/",
        key: 1,
      },
    ],
    englishTabBarItems: [],
    tabBarItems: [] as any,
    startNum: 134278,
    visitorNum: [] as any
  }),
  created: function () {
    const hostname = window.location.hostname;
    if (hostname === 'npu-aslp.org') {
      this.recordInfo = '陕ICP备15008649号-1';
    } else if (hostname === 'nwpu-aslp.org') {
      this.recordInfo = '陕ICP备15008649号-2';
    }
  },
  mounted: function () {
    this.tabBarItems = this.mainTabBarItems;
    this.getStatistics();
  },

  watch: {
    $route(to, from) {
      // 顶部栏右侧动态更改
      if (to.path.startsWith("/english")) {
        this.isEn = true;
      }else if (to.path.startsWith("/english")) {
        this.isEn = false;
      }
      // 顶部栏toolbar动态更改
      if (to.path.startsWith("/backstage")) {
        if (Vue.prototype.logged == false) {
          this.tabBarItems = this.unloggedTabBarItems;
          console.log("router update");
          this.$router.push("/backstage/login");
        } else {
          this.tabBarItems = this.loggedTabBarItems;
        }
      } else if (
        from.path == "/backstage/login" &&
        to.path == "/backstage/article"
      ) {
        this.tabBarItems = this.loggedTabBarItems;
        this.topTab = 0;
      } else if (to.path.startsWith("/backstage")) {
        this.tabBarItems = this.loggedTabBarItems;
      }else if (to.path.startsWith("/english")) {
        this.tabBarItems = this.englishTabBarItems;
      } else {
        this.tabBarItems = this.mainTabBarItems;
      }
      // 更改tab，同步
      console.log("path from " + from.path + " to " + to.path)
      if (to.path.startsWith("/backstage/article")) {
        this.topTab = 0;
      } else if (to.path.startsWith("/introduction") || to.path.startsWith("/backstage/intro")) {
        this.topTab = 1;
      } else if (to.path.startsWith("/article") || to.path.startsWith("/backstage/staff")) {
        this.topTab = 2;
      } else if (to.path.startsWith("/staff") || to.path.startsWith("/backstage/achievement")) {
        this.topTab = 3;
        console.log("toptab:" + this.topTab)
      } else if (to.path.startsWith("/group")) {
        this.topTab = 4;
      } else if (to.path.startsWith("/achievement")) {
        this.topTab = 5;
      } else if (to.path.startsWith("/recommend")) {
        this.topTab = 6;
      } else {
        this.topTab = 0;
      }
      
    },
  },
  methods:{
    getStatistics(){
      axios
      .post(Vue.prototype.serverAddr + "/api/get-statistics", {
      })
      .then((response: any) => {
        var nums = response.data.result.items[1]
        var total = 0
        for(var i = 0; i < nums.length; i++) {
          if(nums[i][0]==="--")
            continue
          total = total + parseInt(nums[i][0])
        }
        this.visitorNum = this.startNum + total
        console.log(this.visitorNum)
      })
      .catch(function (error) {
        console.log(error)
      });
    }
  },

});
