




































import Vue from "vue";
import HomeNews from "@/components/HomeNews.vue";
import HomeRecommend from "@/components/HomeRecommend.vue";
import HomeGroups from "@/components/HomeGroups.vue";

export default Vue.extend({
  components: { HomeNews, HomeRecommend, HomeGroups },
  name: "HelloWorld",

  data: () => ({
    model: 0,
    
    carouselPics: [
      {
        src: require("../assets/carousel/photo01.jpg"),
      },
      {
        src: require("../assets/carousel/photo15.jpg"),
      },
      {
        src: require("../assets/carousel/photo16.jpg"),
      },
      {
        src: require("../assets/carousel/photo17.jpg"),
      },
      {
        src: require("../assets/carousel/photo18.jpg"),
      },
      {
        src: require("../assets/carousel/photo12.jpg"),
      },
      {
        src: require("../assets/carousel/photo07.jpg"),
      },
      {
        src: require("../assets/carousel/photo19.jpg"),
      },
      {
        src: require("../assets/carousel/photo09.jpg"),
      },
    ],
    /**
     * back 20221202
     *       {
        src: require("../assets/carousel/photo01.jpg"),
      },
      {
        src: require("../assets/carousel/photo02.jpg"),
      },
      {
        src: require("../assets/carousel/photo14.jpg"),
      },
      {
        src: require("../assets/carousel/photo11.jpg"),
      },
      {
        src: require("../assets/carousel/photo05.jpg"),
      },
      {
        src: require("../assets/carousel/photo12.jpg"),
      },
      {
        src: require("../assets/carousel/photo07.jpg"),
      },
      {
        src: require("../assets/carousel/photo13.jpg"),
      },
      {
        src: require("../assets/carousel/photo09.jpg"),
      },
     */
    
        /**
    carouselPics: [
      {
        src: require("../assets/carouse1.jpg"),
      },
      {
        src: require("../assets/carouse2.jpg"),
      },
    ],
     */
  }),
});
