import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import VueCookies from 'vue-cookies'
Vue.prototype.serverAddr = "http://39.98.237.41:9094"
Vue.config.productionTip = false
document.title = "西工大音频语音与语言处理研究组"

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')

Vue.use(VueCookies)
Vue.prototype.logged = false
