







































import Vue from "vue";
import axios from "axios";
import HomeModuleHeader from "@/components/HomeModuleHeader.vue";

export default Vue.extend({
  components: { HomeModuleHeader },
  data: () => ({
    newsbig1: [] as any,
    newsbig2: [] as any,
    news: [] as any,
    page: 1,
    numInOnePage: 3,
    newsHeaderProp: {
      title: "新闻公告",
      button: "更多",
      path: "/article/news",
    },
  }),
  mounted: function () {
    this.getBigNews();
    this.getNewsList();
    console.log();
  },
  methods: {
    getBigNews() {
      axios
        .post(Vue.prototype.serverAddr + "/api/get-item-list", {
          collection: "article",
          need: {
            type: "news",
          },
          page: 1,
          numInOnePage: 2,
        })
        .then((response) => {
          console.log("get response");
          if (response.data != null) {
            this.newsbig1 = (response.data as any)[0];
            this.newsbig2 = (response.data as any)[1];
            if(this.newsbig1.imagelist != null){
              this.newsbig1["pic"] = Vue.prototype.serverAddr +  "/" + this.newsbig1.imagelist[0];
            }
            if(this.newsbig2.imagelist != null){
              this.newsbig2["pic"] = Vue.prototype.serverAddr +  "/" + this.newsbig2.imagelist[0];
            }
            //this.newsbig1.content = this.newsbig1.content.substr(0,40);
            //this.newsbig2.content = this.newsbig2.content.substr(0,40);
          }
        });
    },
    getNewsList() {
      axios
        .post(Vue.prototype.serverAddr + "/api/get-item-list", {
          collection: "article",
          need: {
            $or:[
            {type: "news"},{type:"notice"}
            ]
          },
          page: this.page,
          numInOnePage: this.numInOnePage,
        })
        .then((response) => {
          console.log("get response");
          this.news = response.data;
        });
    },
    gotoNews: function (id: string, link: string) {
      if (link != "") {
        window.location.href = link;
      } else {
        this.$router.push({
          path: `/article/news/${id}`,
        });
        console.log(id);
      }
    },
  },
});
