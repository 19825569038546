import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/About.vue')
  },
  {
    path: '/introduction',
    name: 'introduction',
    component: () => import('../views/Introduction.vue')
  },
  {
    path: '/english',
    name: 'english',
    component: () => import('../views/English.vue')
  },
  {
    path: '/article',
    name: 'newsIndex',
    component: () => import('../views/Article.vue'),
    children: [
      {
        path: '',
        name: 'newsIndex',
        component: () => import('../components/ArticleList.vue')
      },
      {
        path: '/article/news',
        name: 'newsIndex',
        component: () => import('../components/ArticleList.vue')
      },
      {
        path: '/article/news/:id',
        name: 'newsDetail',
        component: () => import('../components/ArticleDetail.vue')
      },
      {
        path: '/article/notice',
        name: 'noticeIndex',
        component: () => import('../components/ArticleList.vue')
      },
      {
        path: '/article/notice/:id',
        name: 'noticeDetail',
        component: () => import('../components/ArticleDetail.vue')
      },
    ]
  },
  {
    path: '/recommend',
    name: 'recommendIndex',
    component: () => import('../views/Recommend.vue'),
    children: [
      {
        path: '',
        name: 'recommendIndex',
        component: () => import('../components/ArticleList.vue')
      },
      {
        path: '/recommend/:id',
        name: 'recommendDetail',
        component: () => import('../components/ArticleDetail.vue')
      },
    ]
  },
  {
    path: '/group',
    name: 'ttsIndex',
    component: () => import('../views/Group.vue'),
    children: [
      {
        path: '',
        name: 'groupIndex',
        component: () => import('../components/GroupDetail.vue')
      },
      {
        path: '/group/tts',
        name: 'ttsIndex',
        component: () => import('../components/GroupDetail.vue')
      },
      {
        path: '/group/asr',
        name: 'asrIndex',
        component: () => import('../components/GroupDetail.vue')
      },
      {
        path: '/group/fe',
        name: 'feIndex',
        component: () => import('../components/GroupDetail.vue')
      },
      {
        path: '/group/sv',
        name: 'svIndex',
        component: () => import('../components/GroupDetail.vue')
      },
    ]
  },
  {
    path: '/staff',
    name: 'professor',
    component: () => import('../views/Staff.vue'),
    children: [
      {
        path: '',
        name: 'professor',
        component: () => import('../components/ProfessorList.vue')
      },
      {
        path: '/staff/professor',
        name: 'professor',
        component: () => import('../components/ProfessorList.vue')
      },
      {
        path: '/staff/doctor',
        name: 'doctor',
        component: () => import('../components/StudentList.vue')
      },
      {
        path: '/staff/doctor/:engName',
        name: 'doctor',
        component: () => import('../components/StudentMd.vue')
      },
      {
        path: '/staff/postgraduate',
        name: 'postgraduate',
        component: () => import('../components/StudentList.vue')
      },
      {
        path: '/staff/postgraduate/:engName',
        name: 'postgraduate',
        component: () => import('../components/StudentMd.vue')
      },
      {
        path: '/staff/undergraduates',
        name: 'undergraduates',
        component: () => import('../components/StudentList.vue')
      },
      {
        path: '/staff/undergraduates/:engName',
        name: 'undergraduates',
        component: () => import('../components/StudentMd.vue')
      },
      {
        path: '/staff/graduated',
        name: 'graduated',
        component: () => import('../components/StudentListGraduated.vue')
      },
      {
        path: '/staff/visitor',
        name: 'visitor',
        component: () => import('../components/StudentList.vue')
      },
      {
        path: '/staff/visitor/:engName',
        name: 'visitor',
        component: () => import('../components/StudentMd.vue')
      },
    ]
  },
  {
    path: '/achievement',
    name: 'paperIndex',
    component: () => import('../views/Achievement.vue'),
    children: [
      {
        path: '',
        name: 'paperIndex',
        component: () => import('../components/PaperList.vue')
      },
      {
        path: '/achievement/paper',
        name: 'paperIndex',
        component: () => import('../components/PaperList.vue')
      },
      {
        path: '/achievement/honor',
        name: 'honorIndex',
        component: () => import('../components/HonorList.vue')
      },
    ]
  },
  {
    path: '/backstage',
    name: 'backstage',
    component: () => import('../views/Backstage.vue'),
    children: [
      {
        path: 'login',
        name: 'backstage',
        component: () => import('../components/Login.vue')
      },
      {
        path: '/backstage/article',
        name: 'backstage',
        component: () => import('../components/ArticleManage.vue'),
        children: [
          {
            path: '',
            component: () => import('../components/ItemManage.vue')
          },
          {
            path: ':id',
            component: () => import ('../components/ArticleManageDetail.vue')
          }
        ]
      },
      {
        path: '/backstage/intro',
        name: 'backstage',
        component: () => import('../components/IntroManage.vue'),
        children: [
          {
            path: '',
            component: () => import('../components/ItemManage.vue')
          },
          {
            path: ':id',
            component: () => import ('../components/IntroManageDetail.vue')
          }
        ]
      },
      {
        path: '/backstage/staff',
        name: 'backstage',
        component: () => import('../components/StaffManage.vue'),
        children: [
          {
            path: '',
            component: () => import('../components/ItemManage.vue')
          },
          {
            path: ':id',
            component: () => import ('../components/StaffManageDetail.vue')
          }
        ]
      },
      {
        path: '/backstage/achievement',
        name: 'backstage',
        component: () => import('../components/AchievementManage.vue'),
        children: [
          {
            path: '',
            component: () => import('../components/ItemManage.vue')
          },
          {
            path: ':id',
            component: () => import ('../components/AchievementManageDetail.vue')
          }
        ]
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {x: 0, y: 0};
  }
})

export default router
